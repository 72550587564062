@use 'src/styles/color.scss';
@use 'src/styles/space.scss';

.contents {
    display: grid;
    place-content: center;
    height: 100%;
}
.container {
    flex-direction: column;
}

input {
    background: transparent;
    border-radius: 15px;
}

.scan {
    padding: 7px;
    border: 2px solid color.$gray-lighter;
    border-radius: 15px;
}

.button {
    background: color.$blue-base;
    border-radius: 15px;
    transition: transform 0.1s linear;
    color: color.$navy-contrast;
    position: inherit;
    text-align: center;
    margin: 0 10px;
    border: none;
    padding: 7px 15px;
}

.text {
    padding-top: 20px;
    line-height: 30px;
    font-size: 18px;
    color: color.$gray-base;
    font-weight: normal;
}

.pt35 {
    padding-top: 35px;
}
