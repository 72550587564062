@use 'src/styles/color.scss';
@use 'src/styles/space.scss';
@use 'src/styles/borders.scss';

$-white: #ffffff;
$-black: #000000;
$-gray: #808080;

.textLeft {
    text-align: left;
}

.avatar {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    margin-right: 5px;
}

textarea {
    background: color.$gray-lighter;
    border-radius: borders.$border-radius--button;
    font-size: 16px;
    line-height: 19px;
    padding: 10px 20px;
    color: color.$gray-dark;
    border: none;
}

input {
    background: color.$gray-lighter;
}

input.checkbox {
    height: 25px;
    width: 25px;
}

input:checked {
    background: color.$blue-base;
}

.button-danger-inverted {
    color: color.$red-base;
    background-color: $-white;
    border-radius: borders.$border-radius--button;
    border: borders.$border-input;
    padding: 10px 20px;
    cursor: pointer;
}

textarea:disabled {
    cursor: not-allowed;
}

.gray {
    color: color.$gray-dark;
}

.tabs {
    height: 80px;
    display: flex;
    align-items: center;
    background: color.$gray-lighter;
}

.tabstyle {
    background-color: transparent;
    border: none;
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 10px 25px;
    cursor: pointer;
    color: $-gray;
}
.tabstyleActive {
    color: $-black;
}

.p25 {
    padding: 25px;
}

.fullWidth {
    width: 100%;
}
