@use 'src/styles/color.scss';
@use 'src/styles/space.scss';
@use 'src/styles/fonts.scss';

$-container-bg--color: #ffffff;
$-margin--top-1: 1rem;
$-auto: auto;

.container {
    position: fixed;
    top: 0;
    left: 0;
    width: space.$screen--width-percent;
    height: space.$screen--height-percent;
    text-align: center;
    background-color: $-container-bg--color;
    display: grid;
    justify-items: center;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);

    div,
    .btnContainer,
    a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .logoContainer {
        width: 80%;
        margin-top: $-auto;

        .logo {
            width: calc(256px + #{space.$screen--width} / 20);
        }

        .title {
            font-weight: 600;
            font-size: calc(16px + 0.5vw);
            width: calc(256px + #{space.$screen--width} / 20);
            margin: 0 $-auto;
            margin-top: space.$gap--lg;
            color: color.$navy-base;
        }
    }

    .btnsOuter {
        width: 80%;
        margin-top: $-auto;
        margin-bottom: 10%;
    }

    .btnContainer {
        margin-top: $-margin--top-1;
        flex-direction: row;
        justify-content: space-between;
    }

    a {
        width: 40%;
        margin-top: $-margin--top-1;
        background-color: transparent;
        max-height: 3rem;
        border: none;
        overflow-y: hidden;
    }

    img {
        width: 100%;
    }
}
