@use 'src/styles/color.scss';
@use 'src/styles/space.scss';
@use 'src/styles/fonts.scss';
@use 'src/styles/borders.scss';

$-color--white: #ffffff;

.container {
    position: relative;
    display: flex;
    width: 100%;

    &.open {
        &::after {
            content: url('../../../assets//icons/arrowUp.svg');
        }
        input {
            border-bottom-left-radius: borders.$border-radius--0;
            border-bottom-right-radius: borders.$border-radius--0;
        }
    }

    &::after {
        content: url('../../../assets/icons/arrowDown.svg');
        width: 0.75rem;
        position: absolute;
        right: 0.8rem;
        align-self: center;
        z-index: -1;
    }

    input {
        background-color: transparent;
        border: borders.$border-input;
        box-shadow: none;
        outline: none;
        padding: 0.5rem;
        width: 100%;
        font-size: fonts.$font-size--sm;
    }

    ul {
        list-style: none;
        position: absolute;
        top: 100%;
        z-index: 999999;
        background-color: $-color--white;
        width: 100%;
        max-height: 10em;
        overflow-x: hidden;
        overflow-y: auto;
        border: borders.$border-input;
        border-top: none;
        border-bottom-left-radius: borders.$border-radius--input;
        border-bottom-right-radius: borders.$border-radius--input;

        li {
            padding: 0.5rem;
            text-align: left;
            font-weight: normal;
            font-size: fonts.$font-size--md;
            display: flex;
            cursor: pointer;

            &.empty {
                pointer-events: none;
                cursor: default;
                height: 9.5em;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                & > div {
                    width: 6em;
                    height: 6em;
                }
            }

            &:not(.empty):hover {
                background-color: color.$gray-lighter;
            }

            &:not(.empty)::before {
                content: url('../../../assets/icons/radioButtonOff.svg');
                display: block;
                width: 1rem;
                height: 1rem;
                margin-right: 0.25rem;
            }

            &[aria-selected='true']:not(.empty)::before {
                content: url('../../../assets/icons/radioButtonOn.svg');
            }
        }
    }
}

@media (min-width: space.$breakpoint--xl) {
    .container {
        &::after {
            width: 1rem;
        }

        input {
            font-size: fonts.$font-size--md;
        }
    }
}
