@use './color';
@use './fonts';

// NOTE: Please don't bloat this file with styles for specific components
// and use .module.scss files instead

:root {
    --ion-font-family: 'Roboto', sans-serif;

    --ion-color-melon: #83d156;
    --ion-color-melon-rgb: 131, 209, 86;
    --ion-color-melon-contrast: #000000;
    --ion-color-melon-contrast-rgb: 0, 0, 0;
    --ion-color-melon-shade: #73b84c;
    --ion-color-melon-tint: #8fd667;

    --ion-color-melon-navy: #183f57;
    --ion-color-melon-navy-rgb: 24, 63, 87;
    --ion-color-melon-navy-contrast: #ffffff;
    --ion-color-melon-navy-contrast-rgb: 255, 255, 255;
    --ion-color-melon-navy-shade: #15374d;
    --ion-color-melon-navy-tint: #2f5268;

    --ion-color-melon-blue: #228bdf;
    --ion-color-melon-blue-rgb: 34, 139, 223;
    --ion-color-melon-blue-contrast: #ffffff;
    --ion-color-melon-blue-contrast-rgb: 255, 255, 255;
    --ion-color-melon-blue-shade: #1e7ac4;
    --ion-color-melon-blue-tint: #3897e2;

    --ion-color-melon-orange: #f58122;
    --ion-color-melon-orange-rgb: 245, 129, 34;
    --ion-color-melon-orange-contrast: #000000;
    --ion-color-melon-orange-contrast-rgb: 0, 0, 0;
    --ion-color-melon-orange-shade: #d8721e;
    --ion-color-melon-orange-tint: #f68e38;

    --ion-color-melon-dark-gray: #4d4d4d;
    --ion-color-melon-dark-gray-rgb: 77, 77, 77;
    --ion-color-melon-dark-gray-contrast: #ffffff;
    --ion-color-melon-dark-gray-contrast-rgb: 255, 255, 255;
    --ion-color-melon-dark-gray-shade: #444444;
    --ion-color-melon-dark-gray-tint: #5f5f5f;

    --ion-color-melon-gray: #a5a8aa;
    --ion-color-melon-gray-rgb: 165, 168, 170;
    --ion-color-melon-gray-contrast: #000000;
    --ion-color-melon-gray-contrast-rgb: 0, 0, 0;
    --ion-color-melon-gray-shade: #919496;
    --ion-color-melon-gray-tint: #aeb1b3;

    --ion-color-melon-light-gray: #dcdcdc;
    --ion-color-melon-light-gray-rgb: 220, 220, 220;
    --ion-color-melon-light-gray-contrast: #000000;
    --ion-color-melon-light-gray-contrast-rgb: 0, 0, 0;
    --ion-color-melon-light-gray-shade: #c2c2c2;
    --ion-color-melon-light-gray-tint: #e0e0e0;

    --ion-color-melon-lighter-gray: #efefef;
    --ion-color-melon-lighter-gray-rgb: 239, 239, 239;
    --ion-color-melon-lighter-gray-contrast: #000000;
    --ion-color-melon-lighter-gray-contrast-rgb: 0, 0, 0;
    --ion-color-melon-lighter-gray-shade: #d2d2d2;
    --ion-color-melon-lighter-gray-tint: #f1f1f1;

    --ion-color-melon-red: #ff0021;
    --ion-color-melon-red-rgb: 255, 0, 33;
    --ion-color-melon-red-contrast: #ffffff;
    --ion-color-melon-red-contrast-rgb: 255, 255, 255;
    --ion-color-melon-red-shade: #e0001d;
    --ion-color-melon-red-tint: #ff1a37;

    --ion-color-melon-green-spinner: #89d15f;
    --ion-color-melon-green-spinner-tint: #89d15f40;
}

@media (prefers-color-scheme: light) {
    :root {
        --clr--fg: black;
        --clr--bg: white;
    }
}

// @media (prefers-color-scheme: dark) {
//     :root {
//         --clr--fg: white;
//         --clr--bg: black;
//     }
// }

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    position: relative;
    color: color.$fg;
    background-color: color.$bg;
    font-size: fonts.$font-size--md;
    font-family: var(--ion-font-family);
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.d-flex {
    display: flex;
}

.flex-center {
    justify-content: center;
    align-items: center;
}

.flex-column {
    flex-direction: column;
}

.relative {
    position: relative;
}
