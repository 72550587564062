$gap: 16px;
$gap--xs: 8px;
$gap--sm: 12px;
$gap--md: 16px;
$gap--lg: 20px;

$screen--width: 100vw;
$screen--width-percent: 100%;
$screen--height: 100vh;
$screen--height-percent: 100%;

$navbar--width: 110px;
$navbar--height: 80px;
$navbar--mobile-list-height: 60px;
$navbar--mobile-height: 70px;
$navbar--avatar-diameter: 40px;
$navbar--icon-width: 30px;

$breakpoint--xs: 425px;
$breakpoint--md: 768px;
$breakpoint--tablet: 1024px;
$breakpoint--lg: 1224px;
$breakpoint--xl: 1440px;
$breakpoint--xxl: 1920px;

$logo--w: 120px;
$logo--h: 40px;
