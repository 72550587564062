@use 'src/styles/color.scss';
@use 'src/styles/space.scss';
@use 'src/styles/fonts.scss';
@use 'src/styles/borders.scss';

$button-padding--vertical: 12px;
$button-padding--horizontal: 10px;

.button {
    border: none;
    font-size: fonts.$font-size--md;
    transition: transform 0.1s linear;
    padding: $button-padding--vertical $button-padding--horizontal;
}

.filled {
    background-color: color.$blue-base;
    border-radius: borders.$border-radius--button;
    color: #ffffff;
}

.disabled {
    background-color: transparent;
    color: color.$gray-base;
    border: 1px solid color.$gray-base;
}

.empty {
    background-color: transparent;
    color: color.$blue-base;
}

.button:hover {
    cursor: pointer;
    transform: scale(1.05);
}

.disabled:hover {
    cursor: not-allowed;
    transform: none;
}
