@use 'src/styles/color.scss';
@use 'src/styles/space.scss';
@use 'src/styles/fonts.scss';

$-title-spacing--bottom: space.$gap--md * 2;

.fw400 {
    font-weight: 400;
}
.textColor {
    color: color.$gray-dark;
}
.title {
    margin-bottom: $-title-spacing--bottom;
}
.pt25 {
    padding-top: 25px;
}
.mb1 {
    margin-bottom: 0.25rem;
}
