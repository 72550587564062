@use 'src/styles/color.scss';
@use 'src/styles/space.scss';
@use 'src/styles/borders.scss';

$-white: #ffffff;
$-tomato: #ff8072;
$-light-gray--1: #dddddd;
$-light-gray--2: #f9f9f9;
$-light-gray--3: #f5f5f5;
$-light-gray--4: #e8e8e8;
$-light-green--1: #dff0d8;
$-light-green--2: #d0e9c6;
$-light-blue--1: #d9edf7;
$-light-blue--2: #c4e3f3;
$-light-yellow--1: #fcf8e3;
$-light-yellow--2: #faf2cc;
$-light-red--1: #f2dede;
$-light-red--2: #ebcccc;
$-button--green: #32cd32;

.p35 {
    padding: 35px;
}

.gray {
    color: color.$gray-dark;
    font-weight: 400;
}

.textLeft {
    text-align: left;
}

.avatar {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    margin-right: 5px;
}

.disabled {
    color: color.$gray-dark;
    cursor: not-allowed;
}

.loader {
    position: fixed;
    top: 80px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999998;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba($color: $-white, $alpha: 0.5);
}

textarea {
    background: color.$gray-lighter;
    border-radius: borders.$border-radius--button;
    font-size: 16px;
    line-height: 19px;
    padding: 10px 20px;
    color: color.$gray-dark;
    border: none;
}

input {
    background: color.$gray-lighter;
}

input.checkbox {
    height: 25px;
    width: 25px;
}

input:checked {
    background: color.$blue-base;
}

.button-danger-inverted {
    color: $-tomato;
    background-color: $-white;
    border-radius: borders.$border-radius--button;
    border: borders.$border-input;
    padding: 10px 20px;
    cursor: pointer;
}

.button-danger {
    color: $-white;
    background-color: $-tomato;
    border-radius: borders.$border-radius--button;
    border: 2px solid $-tomato;
    padding: 10px 20px;
    cursor: pointer;
}

textarea:disabled {
    cursor: not-allowed;
}

.empty {
    flex-direction: column;
    height: calc(space.$screen--height - space.$navbar--height * 3);
}

.textCenter {
    > div {
        justify-content: space-around;
        > span {
            line-height: 1;
        }
    }
}

.table {
    border-collapse: collapse !important;
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    background-color: $-white;
    td {
        position: relative;
    }
}
.table-borderless tbody + tbody,
.table-borderless td,
.table-borderless th,
.table-borderless thead th {
    border: 0 !important;
}

.table td,
.table th {
    background-color: $-white !important;
}

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
    padding: 8px 8px 8px 0;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid $-light-gray--1;
}
.table > thead > tr > th {
    vertical-align: bottom;
    border-bottom: 2px solid $-light-gray--1;
}
.table > caption + thead > tr:first-child > td,
.table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > td,
.table > thead:first-child > tr:first-child > th {
    border-top: 0;
}
.table > tbody + tbody {
    border-top: 2px solid $-light-gray--1;
}

.table-condensed > tbody > tr > td,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > td,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > thead > tr > th {
    padding: 5px;
}
.table-bordered {
    border: 1px solid $-light-gray--1;
}
.table-bordered > tbody > tr > td,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > td,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > thead > tr > th {
    border: 1px solid $-light-gray--1;
}
.table-bordered > thead > tr > td,
.table-bordered > thead > tr > th {
    border-bottom-width: 2px;
}
.table-striped > tbody > tr:nth-of-type(odd) {
    background-color: $-light-gray--2;
}
.table-hover > tbody > tr:hover {
    background-color: $-light-gray--3;
}
.table > tbody > tr.active > td,
.table > tbody > tr.active > th,
.table > tbody > tr > td.active,
.table > tbody > tr > th.active,
.table > tfoot > tr.active > td,
.table > tfoot > tr.active > th,
.table > tfoot > tr > td.active,
.table > tfoot > tr > th.active,
.table > thead > tr.active > td,
.table > thead > tr.active > th,
.table > thead > tr > td.active,
.table > thead > tr > th.active {
    background-color: $-light-gray--3;
}
.table-hover > tbody > tr.active:hover > td,
.table-hover > tbody > tr.active:hover > th,
.table-hover > tbody > tr:hover > .active,
.table-hover > tbody > tr > td.active:hover,
.table-hover > tbody > tr > th.active:hover {
    background-color: $-light-gray--4;
}
.table > tbody > tr.success > td,
.table > tbody > tr.success > th,
.table > tbody > tr > td.success,
.table > tbody > tr > th.success,
.table > tfoot > tr.success > td,
.table > tfoot > tr.success > th,
.table > tfoot > tr > td.success,
.table > tfoot > tr > th.success,
.table > thead > tr.success > td,
.table > thead > tr.success > th,
.table > thead > tr > td.success,
.table > thead > tr > th.success {
    background-color: $-light-green--1;
}
.table-hover > tbody > tr.success:hover > td,
.table-hover > tbody > tr.success:hover > th,
.table-hover > tbody > tr:hover > .success,
.table-hover > tbody > tr > td.success:hover,
.table-hover > tbody > tr > th.success:hover {
    background-color: $-light-green--2;
}
.table > tbody > tr.info > td,
.table > tbody > tr.info > th,
.table > tbody > tr > td.info,
.table > tbody > tr > th.info,
.table > tfoot > tr.info > td,
.table > tfoot > tr.info > th,
.table > tfoot > tr > td.info,
.table > tfoot > tr > th.info,
.table > thead > tr.info > td,
.table > thead > tr.info > th,
.table > thead > tr > td.info,
.table > thead > tr > th.info {
    background-color: $-light-blue--1;
}
.table-hover > tbody > tr.info:hover > td,
.table-hover > tbody > tr.info:hover > th,
.table-hover > tbody > tr:hover > .info,
.table-hover > tbody > tr > td.info:hover,
.table-hover > tbody > tr > th.info:hover {
    background-color: $-light-blue--2;
}
.table > tbody > tr.warning > td,
.table > tbody > tr.warning > th,
.table > tbody > tr > td.warning,
.table > tbody > tr > th.warning,
.table > tfoot > tr.warning > td,
.table > tfoot > tr.warning > th,
.table > tfoot > tr > td.warning,
.table > tfoot > tr > th.warning,
.table > thead > tr.warning > td,
.table > thead > tr.warning > th,
.table > thead > tr > td.warning,
.table > thead > tr > th.warning {
    background-color: $-light-yellow--1;
}
.table-hover > tbody > tr.warning:hover > td,
.table-hover > tbody > tr.warning:hover > th,
.table-hover > tbody > tr:hover > .warning,
.table-hover > tbody > tr > td.warning:hover,
.table-hover > tbody > tr > th.warning:hover {
    background-color: $-light-yellow--2;
}
.table > tbody > tr.danger > td,
.table > tbody > tr.danger > th,
.table > tbody > tr > td.danger,
.table > tbody > tr > th.danger,
.table > tfoot > tr.danger > td,
.table > tfoot > tr.danger > th,
.table > tfoot > tr > td.danger,
.table > tfoot > tr > th.danger,
.table > thead > tr.danger > td,
.table > thead > tr.danger > th,
.table > thead > tr > td.danger,
.table > thead > tr > th.danger {
    background-color: $-light-red--1;
}
.table-hover > tbody > tr.danger:hover > td,
.table-hover > tbody > tr.danger:hover > th,
.table-hover > tbody > tr:hover > .danger,
.table-hover > tbody > tr > td.danger:hover,
.table-hover > tbody > tr > th.danger:hover {
    background-color: $-light-red--2;
}
.table-responsive {
    min-height: 0.01%;
    overflow-x: auto;
}
@media screen and (max-width: 767px) {
    .table-responsive {
        width: 100%;
        margin-bottom: 15px;
        overflow-y: hidden;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        border: 1px solid $-light-gray--1;
    }
    .table-responsive > .table {
        margin-bottom: 0;
    }
    .table-responsive > .table > tbody > tr > td,
    .table-responsive > .table > tbody > tr > th,
    .table-responsive > .table > tfoot > tr > td,
    .table-responsive > .table > tfoot > tr > th,
    .table-responsive > .table > thead > tr > td,
    .table-responsive > .table > thead > tr > th {
        white-space: nowrap;
    }
    .table-responsive > .table-bordered {
        border: 0;
    }
    .table-responsive > .table-bordered > tbody > tr > td:first-child,
    .table-responsive > .table-bordered > tbody > tr > th:first-child,
    .table-responsive > .table-bordered > tfoot > tr > td:first-child,
    .table-responsive > .table-bordered > tfoot > tr > th:first-child,
    .table-responsive > .table-bordered > thead > tr > td:first-child,
    .table-responsive > .table-bordered > thead > tr > th:first-child {
        border-left: 0;
    }
    .table-responsive > .table-bordered > tbody > tr > td:last-child,
    .table-responsive > .table-bordered > tbody > tr > th:last-child,
    .table-responsive > .table-bordered > tfoot > tr > td:last-child,
    .table-responsive > .table-bordered > tfoot > tr > th:last-child,
    .table-responsive > .table-bordered > thead > tr > td:last-child,
    .table-responsive > .table-bordered > thead > tr > th:last-child {
        border-right: 0;
    }
    .table-responsive > .table-bordered > tbody > tr:last-child > td,
    .table-responsive > .table-bordered > tbody > tr:last-child > th,
    .table-responsive > .table-bordered > tfoot > tr:last-child > td,
    .table-responsive > .table-bordered > tfoot > tr:last-child > th {
        border-bottom: 0;
    }
}

@media screen and (max-width: 1100px) {
    .scroll {
        overflow-x: scroll;
    }
}

.lightGray {
    color: color.$gray-base;
    font-weight: 400;
}
/* iPads (landscape) ----------- */
@media only screen and (max-width: space.$breakpoint--tablet) {
    textarea {
        width: 100% !important;
    }
    td {
        font-size: 15px;
        inline-size: min-content;
    }
}

/* iPads (portrait) ----------- */
@media only screen and (max-width: space.$breakpoint--tablet) {
    .scroll {
        overflow-x: scroll;
    }
    textarea {
        width: 100% !important;
    }
    td {
        font-size: 13px !important;
        inline-size: min-content;
        overflow: hidden;
    }
}

.button-default {
    color: $-button--green;
    background-color: $-white;
    border-radius: borders.$border-radius--button;
    border: 2px solid $-white;
    padding: 10px;
}
