@use 'src/styles/color.scss';
@use 'src/styles/space.scss';
@use 'src/styles/fonts.scss';
@use 'src/styles/borders.scss';

$-textInput-placeholder--width: 70%;
$-color--white: #ffffff;

.modalConfirm {
    width: 627px !important;
}
.container {
    background-color: $-color--white;
    padding: 1.5rem;
    border-radius: borders.$border-radius--button-pill;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    + button {
        margin-right: 0;
    }
}

textarea {
    width: 100%;
    height: 90px;
    border: none;
    background: color.$gray-lighter;
    border-radius: borders.$border-radius--button;
    resize: none !important;
    padding: 15px;
}
textarea:focus {
    outline: none;
}

.note {
    margin-top: calc(space.$gap--lg * 2);
    margin-bottom: calc(space.$gap--sm * 0.8);
    > span {
        color: color.$gray-base;
        font-weight: 400;
    }
}

.noteInput {
    width: 100%;
    min-height: 80px;
    padding: space.$gap--md space.$gap--lg;
    font-family: 'Roboto', sans-serif;
    font-size: fonts.$font-size--md;
    font-weight: 400;
    background-color: color.$gray-lighter;
    color: color.$gray-base;
    border: none;
    border-radius: borders.$border-radius--button;
}

.noteInput::-webkit-input-placeholder {
    width: $-textInput-placeholder--width;
}

.noteInput:-moz-placeholder {
    width: $-textInput-placeholder--width;
}

.noteInput::-moz-placeholder {
    width: $-textInput-placeholder--width;
}

.noteInput:-ms-input-placeholder {
    width: $-textInput-placeholder--width;
}

.noteInput:focus-visible {
    outline-color: color.$navy-base;
}

.noteInputLengthError {
    visibility: hidden;
    color: color.$red-base !important;
    margin-top: space.$gap--sm;
}

.showNoteInputError {
    visibility: visible;
}

.confirmBtn {
    margin-left: auto;
    padding: space.$gap--sm space.$gap--md;
    border-radius: borders.$border-radius--button-pill;
}
