@use 'src/styles/color.scss';
@use 'src/styles/borders.scss';

$-font-size: 18px;

.text {
    font-size: $-font-size;
    font-weight: 400;
    color: color.$gray-dark;
    width: 400px;
    background-color: color.$gray-lighter;
    border-radius: borders.$border-radius--button;
    padding: 9px 15px;
    margin-top: 18%;
    display: flex;
    align-items: center;
}
.scanIcon {
    margin-right: 12px;
    display: flex;
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
