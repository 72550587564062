@use './color.scss';

$border-input: 2px solid color.$gray-light;
$border-black: 1px solid #000000;

$border-radius--0: 0;
$border-radius--input: 10px;
$border-radius--button: 15px;
$border-radius--button-pill: 20px;
$border-radius--50-percent: 50%;
