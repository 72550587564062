@use 'src/styles/space.scss';
@use 'src/styles/borders.scss';

$-white: #ffffff;

.map {
    background-color: $-white;
    width: 100%;
}
.popUpModal {
    width: space.$screen--width-percent;
    min-height: calc(space.$screen--height / 2);
    border-radius: borders.$border-radius--button;
    overflow: hidden;
    padding: 2rem 4rem;
    background-color: $-white;
}
.popUpModalImg {
    width: 100%;
}
