@use 'src/styles/color.scss';
@use 'src/styles/space.scss';

.contents {
    padding: space.$gap--lg;

    .filtersContainer {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        gap: space.$gap--xs;
        margin-bottom: calc(space.$gap--lg * 2);

        .exportBtn {
            color: #ffffff;
        }
    }
}

@media (min-width: space.$breakpoint--xxl) {
    .contents {
        padding: calc(space.$gap--lg * 1.5) 5%;
    }
}
