@use 'src/styles/color.scss';
@use 'src/styles/space.scss';

$button-w: 30px;
$button-h: 30px;
$button-position-top: 5%;
$button-position-right: 4%;

$span-w: 100%;
$span-h: 3px;
$span-border-radius: 2px;
$span-rotation-degrees: 45deg;

.button {
    width: $button-w;
    height: $button-h;
    background: none;
    border: none;
    position: fixed;
    top: $button-position-top;
    right: $button-position-right;
    transition: transform 0.1s linear;
    & > span {
        display: block;
        width: $span-w;
        height: $span-h;
        border-radius: $span-border-radius;
        background-color: color.$gray-base;
        position: absolute;
    }
    & > span:first-child {
        transform: rotate($span-rotation-degrees);
    }
    & > span:last-child {
        transform: rotate(-$span-rotation-degrees);
    }
}

.button:hover {
    cursor: pointer;
    transform: scale(1.1);
}
.button::after {
    content: '';
    position: static;
}
