@use 'src/styles/color.scss';
@use 'src/styles/space.scss';
@use 'src/styles/borders.scss';

$modal-min-w: 50%;
$modal-padding--vertical: 24px;
$modal-padding--horizontal: 30px;

.background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: space.$screen--height;
    background-color: color.$gray-base;
    opacity: 0;
    animation: fadeIn 0.2s linear forwards;
    display: none;
}

.modal {
    overflow: hidden;
    border-radius: borders.$border-radius--button;
    background-color: color.$bg;
    width: 50%;
    height: auto;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: left;
    flex-direction: column;
    display: none;
    opacity: 0;
    animation: popInDesktop 0.3s linear forwards, fadeInModal 0.3s linear forwards;
}

.showModal {
    display: flex;
}

@keyframes fadeIn {
    to {
        opacity: 0.4;
    }
}

@keyframes fadeInModal {
    to {
        opacity: 1;
    }
}
