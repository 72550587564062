@use 'src/styles/color.scss';
@use 'src/styles/space.scss';
@use 'src/styles/fonts.scss';
@use 'src/styles/borders.scss';

$button-padding--vertical: 14px;
$button-padding--horizontal: 20px;

.container {
    text-align: center;
    flex-direction: column;
    width: space.$screen--width-percent;
    height: calc(#{space.$screen--height});
}

.logo {
    width: calc(256px + #{space.$screen--width} / 20);
}

.text {
    font-size: calc(16px + 0.5vw);
    width: calc(256px + #{space.$screen--width} / 20);
    margin-top: 20px;
    margin-bottom: 60px;
    color: color.$navy-base;
}

.logInButton {
    background-color: color.$blue-base;
    border-radius: borders.$border-radius--button-pill;
    color: color.$navy-contrast;
    font-size: fonts.$font-size--xl;
    font-weight: 400;
    text-decoration: none;
    transition: transform 0.1s linear;
    padding: $button-padding--vertical $button-padding--horizontal;
}

.logInButton:hover {
    transform: scale(1.05);
}

@media (max-width: space.$breakpoint--md) {
    .text {
        font-size: calc(16px + 1.2vw);
        width: calc(256px + #{space.$screen--width}/ 10);
    }
}
