@use 'src/styles/color.scss';
@use 'src/styles/space.scss';

.contents {
    display: grid;
    place-content: center;
    height: 100%;
}

input {
    background: color.$gray-lighter;
}

input.checkbox {
    height: 25px;
    width: 25px;
}

input:checked {
    background: #228bdf;
}

@media only screen and (max-width: 1224px) {
    input.checkbox {
        height: 18px;
        width: 18px;
    }
}
