@use 'src/styles/color.scss';
@use 'src/styles/fonts.scss';
@use 'src/styles/borders.scss';

$-white-gray: #e2e2e2;

.container {
    width: 100%;
    position: relative;
    * {
        color: color.$gray-dark;
        font-size: fonts.$font-size--md;
    }

    button {
        border: 0;
        border-radius: borders.$border-radius--input;
        background-color: color.$gray-lighter;
        width: 100%;
        padding: 1rem 0.75rem;
        position: relative;
        text-align: left;

        &:focus-visible {
            outline: none;
        }

        &:hover {
            cursor: pointer;
        }

        &::after {
            content: url('../../../assets/icons/arrowDown.svg');
            position: absolute;
            right: 14px;
        }

        &.expanded {
            border-bottom-left-radius: borders.$border-radius--0;
            border-bottom-right-radius: borders.$border-radius--0;
        }

        &.expanded::after {
            content: url('../../../assets/icons/arrowUp.svg');
        }
    }

    ul.options {
        border: borders.$border-black;
        display: none;
        list-style: none;
        padding: 4px 0;
        background-color: color.$gray-lighter;
        border: none;
        border-top: 1px solid color.$gray-base;
        border-bottom-left-radius: borders.$border-radius--input;
        border-bottom-right-radius: borders.$border-radius--input;
        z-index: 10000;
        font-weight: 400;

        &.show {
            display: block;
            position: absolute;
            top: 3.15rem;
            left: 0;
            right: 0;
        }

        li {
            padding: 0.75rem 10px;
            display: flex;

            &:before {
                content: url('../../../assets/icons/radioButtonOff.svg');
                display: block;
                width: 1rem;
                height: 1rem;
                margin-right: 0.5rem;
            }

            &:active,
            &:focus,
            &:hover,
            &[aria-selected='true'] {
                background: $-white-gray;
                cursor: pointer;
            }

            &[aria-selected='true']::before {
                content: url('../../../assets/icons/radioButtonOn.svg');
            }
        }
    }
}
