@use 'src/styles/color.scss';
@use 'src/styles/space.scss';
@use 'src/styles/fonts.scss';
@use 'src/styles/borders.scss';

$-dropdown-width: 320px;
$-white: #ffffff;
$-grey: #cadbdb;

.container {
    position: relative;
    display: flex;

    &::after {
        content: url('../../../assets/icons/calendar.svg');
        width: 0.75rem;
        position: absolute;
        right: 0.8rem;
        align-self: center;
        z-index: -1;
    }

    input {
        background-color: transparent;
        border: borders.$border-input;
        border-radius: borders.$border-radius--button;
        box-shadow: none;
        outline: none;
        padding: 0.5rem;
        width: 100%;
        font-size: fonts.$font-size--sm;
    }

    .dropdown {
        width: $-dropdown-width;
        position: absolute;
        top: 100%;
        z-index: 999999;
        background-color: color.$gray-lighter;
        border-radius: borders.$border-radius--input;
        padding: 1rem;

        * {
            font-weight: normal;
            outline: none;
            font-size: fonts.$font-size--md;
        }

        :global {
            .rdp-months {
                width: 100%;
            }

            .rdp-day_today {
                background-color: $-grey;
            }

            .rdp-nav {
                width: 100%;
                display: flex;
                justify-content: space-between;
            }

            .rdp-month,
            .rdp-caption,
            .rdp-table {
                max-width: 100%;
                width: 100%;
                margin: 0;
            }

            .rdp-head_cell:first-child,
            .rdp-head_cell:last-child,
            .rdp-cell:first-child,
            .rdp-cell:last-child {
                display: none;
            }

            .rdp-caption {
                margin-bottom: 0.5rem;
                font-size: fonts.$font-size--sm;
                color: color.$gray-base;

                h2 {
                    font-weight: 400;
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }

            .rdp-cell {
                button {
                    margin: 0 auto;
                }
            }

            .rdp-button {
                color: color.$gray-dark;
            }

            .rdp-button:hover:not([aria-disabled='true']) {
                color: color.$gray-dark;
                background-color: color.$gray-light;
            }

            .rdp-day_selected:not([aria-disabled='true']),
            .rdp-day_selected:active:not([aria-disabled='true']),
            .rdp-day_selected:focus:not([aria-disabled='true']) {
                border: none;
                color: $-white;
                background-color: color.$gray-base;
            }

            .rdp-button:active,
            .rdp-button:focus {
                border: none;
                color: color.$gray-dark;
                background-color: transparent;
            }

            .rdp-button_reset.rdp-button.rdp-nav_button.rdp-nav_button_previous,
            .rdp-button_reset.rdp-button.rdp-nav_button.rdp-nav_button_next {
                color: $-white;
                background-color: color.$gray-light;

                &:hover {
                    background-color: color.$gray-base;
                }
            }
        }
    }
}

@media (min-width: space.$breakpoint--xl) {
    .container {
        &::after {
            width: 1rem;
        }
        input {
            font-size: fonts.$font-size--md;
        }
    }
}
