@use 'src/styles/color.scss';
@use 'src/styles/space.scss';
@use 'src/styles/fonts.scss';
@use 'src/styles/borders.scss';

$-color--white: #ffffff;
$-container-w: 100%;

.cancelModal {
    width: 40%;
}

.container {
    width: $-container-w;
    flex-direction: column;
    justify-content: flex-end;
    background-color: $-color--white;
    padding: 2rem 1.5rem 1rem;
    align-self: center;
    border-radius: borders.$border-radius--button;
    > button {
        margin-right: space.$gap--lg * 1.5;
    }
    + button {
        display: none;
    }
}

.btnContainer {
    justify-content: flex-end;
    > button:last-child {
        margin-left: 5%;
    }
}

@media (min-width: space.$breakpoint--xxl) {
    .container {
        max-width: calc(space.$screen--height-percent / 2);
    }
}

@media (min-width: space.$breakpoint--xl) and (max-width: space.$breakpoint--xxl) {
    .container {
        max-width: calc(space.$screen--height-percent / 1.5);
    }
}
