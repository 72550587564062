@use 'src/styles/color.scss';
@use 'src/styles/space.scss';
@use 'src/styles/fonts.scss';

$-logo-margins--centered: 0 space.$gap--lg * 2.25 space.$gap--sm * 0.7 space.$gap--lg * 1.25;
$-logout-icon--spacing: space.$gap * 0.5;
$-gap--between-links: 14%;
$-log-in-out--color: #c5c5c5;

.container,
.container > *,
.logInOut,
.navLinksList {
    display: flex;
}

.container {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    background-color: color.$navy-base;
    color: color.$navy-contrast;
    height: space.$navbar--height;
    > * {
        align-items: center;
    }
}
.navBarRight {
    margin: 0 28px;
}
.logo {
    pointer-events: none;
    margin: $-logo-margins--centered;
    width: space.$logo--w;
    height: space.$logo--h;
}

.logInOut {
    color: $-log-in-out--color;
    background-color: transparent;
    text-decoration: none;
    margin: space.$gap--xs;
    place-items: center;
    > img {
        margin-right: $-logout-icon--spacing;
    }
}

.avatar {
    width: space.$navbar--avatar-diameter;
    height: space.$navbar--avatar-diameter;
    border-radius: 100%;
    margin: space.$gap--xs;
}

.navLinksList {
    list-style-type: none;
    justify-content: space-between;
    > li > a {
        text-decoration: none;
        color: var(--ion-color-melon-gray);
        font-size: fonts.$font-size--lg;
        line-height: fonts.$font-line-height--lg;
        svg {
            display: none;
            path,
            rect {
                fill: color.$gray-base;
            }
        }
    }
    > li:not(:last-child) {
        margin-right: $-gap--between-links;
    }
    > li > a:hover,
    > li > a.active {
        color: color.$bg;
        > svg {
            path,
            rect {
                fill: color.$bg;
            }
        }
    }
}

@media (max-width: space.$breakpoint--tablet) {
    .navLinksList {
        width: space.$screen--width;
        height: space.$navbar--mobile-height;
        padding: 0 space.$gap--lg;
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 999999;
        background-color: color.$navy-base;
        justify-content: space-around;

        &,
        > li,
        > li > a {
            display: flex;
        }

        > li {
            justify-content: center;
            align-items: center;
            > a {
                flex-direction: column;
                place-items: center;
                svg {
                    width: space.$navbar--icon-width;
                    display: block;
                }
            }
            .active {
                svg {
                    path,
                    rect {
                        fill: color.$bg;
                    }
                }
            }
        }
        li:nth-child(1) {
            order: 0;
        }

        li:nth-child(2) {
            order: 3;
        }
        li:nth-child(3) {
            order: 2;
        }
        li:nth-child(4) {
            order: 4;
        }
        li:nth-child(5) {
            order: 5;
        }
        li:not(:last-child) {
            margin-right: 0;
        }
    }
}

@media (max-width: 425px) {
    .container {
        padding: 0 space.$gap;
    }
    .avatar,
    .logo {
        margin: 0;
    }
    .logInOut {
        margin: 0px 0px 0px space.$gap--sm;
    }
    .navLinksList {
        > li {
            flex: 1;
            > a {
                font-size: fonts.$font-size--xs;
                line-height: fonts.$font-line-height--xs;
            }
        }
    }
}

@keyframes logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.disabled {
    color: color.$gray-dark;
    cursor: not-allowed;
}
