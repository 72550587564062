// convenience file for not writing var() all the time
// colors should be updated in :root

$fg: var(--clr--fg);
$bg: var(--clr--bg);

$navy-base: var(--ion-color-melon-navy);
$navy-base-rgb: var(--ion-color-melon-navy-rgb);
$navy-contrast: var(--ion-color-melon-navy-contrast);
$navy-contrast-rgb: var(--ion-color-melon-navy-contrast-rgb);
$navy-shade: var(--ion-color-melon-navy-shade);
$navy-tint: var(--ion-color-melon-navy-tint);

$blue-base: var(--ion-color-melon-blue);
$blue-base-rgb: var(--ion-color-melon-blue-rgb);
$blue-contrast: var(--ion-color-melon-blue-contrast);
$blue-contrast-rgb: var(--ion-color-melon-blue-contrast-rgb);
$blue-shade: var(--ion-color-melon-blue-shade);
$blue-tint: var(--ion-color-melon-blue-tint);

$gray-base: var(--ion-color-melon-gray);
$gray-light: var(--ion-color-melon-light-gray);
$gray-lighter: var(--ion-color-melon-lighter-gray);
$gray-dark: var(--ion-color-melon-dark-gray);

$red-base: var(--ion-color-melon-red);

$green-spinner-base: var(--ion-color-melon-green-spinner);
$green-spinner-tint: var(--ion-color-melon-green-spinner-tint);
