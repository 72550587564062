@use 'src/styles/space.scss';
@use 'src/styles/color.scss';
@use 'src/styles/fonts.scss';
@use 'src/styles/borders.scss';

.container {
    &,
    table {
        width: 100%;
    }

    table {
        border-collapse: collapse;
        text-align: left;
        table-layout: fixed;
    }

    th,
    td {
        border: borders.$border-input;
        padding: space.$gap--sm;
        font-size: fonts.$font-size--lg;
        font-weight: normal;
        overflow-x: auto;
        // white-space: nowrap;

        &:first-child,
        &:nth-child(6),
        &:nth-child(7) {
            width: 20%;
        }
    }

    tr.empty {
        td {
            padding-top: 27rem;
            text-align: center;
            border: none;
            font-size: fonts.$font-size--xl;
        }
    }
}

@media (max-width: space.$breakpoint--xl) {
    .container {
        th,
        td {
            &:first-child,
            &:nth-child(6),
            &:nth-child(7) {
                width: unset;
                white-space: nowrap;
            }
            &:nth-child(3),
            &:nth-child(5) {
                width: 10%;
            }
            &:nth-child(4) {
                width: 7%;
            }
        }
    }
}
