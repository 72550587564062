@use 'src/styles/color.scss';

.deskCode {
    display: none;
    rect {
        fill: color.$gray-lighter;
        transform: translateY(-4px);
    }
    text {
        tspan {
            fill: color.$fg;
            font-size: 4px;
        }
    }
}

.showDeskCode {
    position: fixed;
    z-index: 99999 !important;
    display: block;
    border: none;
    outline: 0;
}
