@use 'src/styles/color.scss';
@use 'src/styles/space.scss';
@use 'src/styles/fonts.scss';

@media screen and (min-width: 1100px) {
    .fullWidth {
        width: space.$screen--width-percent;
    }
}

.container {
    position: relative;
    min-height: 7rem;
}

.contents {
    display: grid;
    place-content: center;
    height: 100%;
}

input {
    background: color.$gray-lighter;
}

input.checkbox {
    height: 25px;
    width: 25px;
}

input:checked {
    background: #228bdf;
}

.noteInputLengthError {
    visibility: hidden;
    position: absolute;
    bottom: 0;
    font-size: 14px;
    line-height: fonts.$font-line-height--xs;
    color: color.$red-base;
}

.showNoteInputError {
    visibility: visible;
}

@media (max-width: 1157px) {
    .noteInputLengthError {
        font-size: 12px;
    }
}

@media (max-width: space.$breakpoint--tablet) {
    .noteInputLengthError {
        font-size: fonts.$font-size--xs;
    }
}

@media (max-width: space.$breakpoint--md) {
    .noteInputLengthError {
        bottom: -1rem;
    }
}
