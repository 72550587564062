@use 'src/styles/color.scss';
@use 'src/styles/space.scss';

.container {
    text-align: center;
    width: space.$screen--width-percent;
    height: calc(#{space.$screen--height} - #{space.$navbar--height});
}

@media (max-width: space.$breakpoint--md) {
    .container {
        height: calc(#{space.$screen--height} - (#{space.$navbar--height} + #{space.$navbar--mobile-list-height}));
    }
}
