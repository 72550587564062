@use 'src/styles/space.scss';
@use 'src/styles/color.scss';

.container {
    width: 50%;
    z-index: 999999;
}
.wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    display: flex;
    flex-direction: column;
    padding: 40px;
    z-index: 99999;
}

.logo {
    width: 125px;
    margin: 0 auto;
}

.text {
    font-weight: 400;
    font-size: calc(16px + 0.5vw);
    width: calc(256px + #{space.$screen--width} / 20);
    color: #4d4d4d;
    text-align: center;
    margin-top: 45px;
}
