@use 'src/styles/color.scss';
@use 'src/styles/fonts.scss';
@use 'src/styles/space.scss';
@use 'src/styles/borders.scss';

$-white: #ffffff;
$-grey: #cadbdb;
$-red: #ff0000;

.container {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    background-color: color.$gray-lighter;
    border-radius: borders.$border-radius--input;

    & :global(input.react-datepicker-ignore-onclickoutside) {
        border-bottom-left-radius: borders.$border-radius--0;
        border-bottom-right-radius: borders.$border-radius--0;
        background-color: color.$gray-lighter;
    }

    &:focus-visible {
        outline: none;
    }

    * {
        font-weight: normal;
        outline: none;
        font-size: fonts.$font-size--md;
    }

    .holiday {
        color: $-red !important;
        pointer-events: none;
        opacity: 1;
    }

    .dateButton {
        border: 0;
        border-radius: borders.$border-radius--input;
        background-color: color.$gray-lighter;
        width: 100%;
        padding: 1rem 0.75rem;
        position: relative;
        text-align: left;

        &:focus-visible {
            outline: none;
        }

        &:hover {
            cursor: pointer;
        }

        &::after {
            content: url('../../../assets/icons/arrowDown.svg');
            position: absolute;
            right: 14px;
        }

        &.expanded {
            border-bottom-left-radius: borders.$border-radius--0;
            border-bottom-right-radius: borders.$border-radius--0;
        }

        &.expanded::after {
            content: url('../../../assets/icons/arrowUp.svg');
        }
    }

    :global {
        .rdp.rdp-multiple_months {
            margin: 0;
        }

        .rdp-months {
            position: absolute;
            left: 0;
            top: 3.2rem;
            z-index: 9999;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            background-color: color.$gray-lighter;
            border-top: 1px solid color.$gray-base;
            border-bottom-left-radius: borders.$border-radius--input;
            border-bottom-right-radius: borders.$border-radius--input;
        }

        .rdp-month,
        .rdp-caption,
        .rdp-table {
            max-width: 100%;
            width: 100%;
            margin: 0;
        }

        .rdp-month {
            padding: 1rem;
        }

        .rdp-day_today {
            background-color: $-grey;
        }

        .rdp-head_cell:first-child,
        .rdp-head_cell:last-child,
        .rdp-cell:first-child,
        .rdp-cell:last-child {
            display: none;
        }

        .rdp-caption {
            margin-bottom: 0.75rem;
            font-size: fonts.$font-size--sm;
            color: color.$gray-base;
            width: auto;

            h2 {
                font-weight: 400;
            }

            .rdp-nav {
                margin-left: 7.5%;
                margin-right: 7.5%;
            }
        }

        .rdp-cell {
            button {
                margin: 0.15rem auto;
            }
        }

        .rdp-button {
            color: color.$gray-dark;
        }

        .rdp-button[aria-disabled='true'] {
            pointer-events: none;
        }

        .rdp-button:hover:not([aria-disabled='true']) {
            color: color.$gray-dark;
            background-color: color.$gray-light;
        }

        .rdp-day_selected:not([aria-disabled='true']),
        .rdp-day_selected:active:not([aria-disabled='true']),
        .rdp-day_selected:focus:not([aria-disabled='true']) {
            border: none;
            color: $-white;
            background-color: color.$gray-base;
        }

        .rdp-button:active,
        .rdp-button:focus {
            border: none;
            color: color.$gray-dark;
            background-color: transparent;
        }

        .rdp-button_reset.rdp-button.rdp-nav_button.rdp-nav_button_previous,
        .rdp-button_reset.rdp-button.rdp-nav_button.rdp-nav_button_next {
            color: $-white;
            background-color: color.$gray-light;

            &:hover {
                background-color: color.$gray-base;
            }
        }
    }
}

@media (min-width: space.$breakpoint--lg) {
    .container {
        &::after {
            right: space.$gap--lg;
        }
    }
}
