@use 'src/styles/color.scss';
@use 'src/styles/space.scss';

.textSVG {
    color: white;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    font-family: var(--ion-font-family);
}
.textCentered {
    text-align: center;
}
.location1 {
    position: absolute;
    width: 8%;
    height: auto;
    left: 19%;
    top: 17%;
    cursor: pointer;
}

.location2 {
    position: absolute;
    width: 8%;
    height: auto;
    left: 69%;
    top: 20%;
    cursor: pointer;
}

.location3 {
    position: absolute;
    width: 8%;
    height: auto;
    left: 17%;
    top: 48%;
    cursor: pointer;
}

.location4 {
    position: absolute;
    width: 8%;
    height: auto;
    left: 21%;
    top: 81%;
    cursor: pointer;
}

.background {
    height: calc(100vh - 80px);
    width: calc(100vw);
    background: url('../../../assets/location-picker.svg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.notAllowed {
    cursor: not-allowed;
}

@media (max-width: space.$breakpoint--tablet) {
    .background {
        background: url('../../../assets/Pick-A-Location.svg') no-repeat center center;
        height: calc(130vh - 525px);
        width: calc(100vw);
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }

    .location1 {
        width: 16%;
        left: 5%;
        top: 60%;
    }

    .location2 {
        width: 15%;
        left: 42%;
        top: 68%;
    }

    .location3 {
        width: 20%;
        left: 74%;
        top: 68%;
    }
    .location4 {
        width: 20%;
        left: 16%;
        top: 79%;
    }
}
