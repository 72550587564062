@use 'src/styles/color.scss';
@use 'src/styles/borders.scss';

.loader {
    position: relative;
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    z-index: 9000;
    background-color: color.$bg;
    margin: 0 auto;
    align-self: center;
}

.spinner {
    border: 1rem solid color.$green-spinner-tint;
    border-top: 1rem solid color.$green-spinner-base;
    width: 100%;
    height: 100%;
    border-radius: borders.$border-radius--50-percent;
    animation: spin 2s linear infinite;
}

.absolute {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
