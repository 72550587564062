@use 'src/styles/color.scss';
@use 'src/styles/space.scss';
@use 'src/styles/fonts.scss';
@use 'src/styles/borders.scss';

$-white: #ffffff;
$-space--1: 1rem;

.contents,
.column,
.flex {
    display: flex;
}

.contents {
    justify-content: space-between;
    padding: 2rem;
}
.w25 {
    width: 25%;
}
.w30 {
    width: 30%;
}
.w50 {
    width: 50%;
}
.w70 {
    width: 70%;
}
.w80 {
    width: 80%;
}
.w100 {
    width: 100%;
}
.mt10 {
    margin-top: 10%;
}
.marginHorizontalAuto {
    margin-left: auto;
    margin-right: auto;
}
.flexWrap {
    flex-wrap: wrap;
}
.textLeft {
    text-align: left;
}
.column {
    flex-direction: column;
    justify-content: space-between;
    padding-right: 2rem;
}
.avatar {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    margin-right: 5px;
}
.title {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: color.$gray-base;
    text-align: left;
    margin-bottom: $-space--1;
}

.gray {
    color: color.$gray-dark;
}
.officeMapView {
    position: relative;
    width: 66%;
}
.reserveBtn {
    border-radius: borders.$border-radius--button-pill;
    padding: space.$gap--sm space.$gap--md;
    margin-left: auto;
    margin-right: 3rem;
}

.viewMapLink {
    cursor: pointer;
    color: color.$blue-base;
    text-decoration: none;
}

.deskPicker {
    min-height: 41vh;
    display: flex;
}
.noDateChosenMessage {
    align-self: center;
    text-align: center;
}

@media (max-width: space.$breakpoint--lg) {
    .contents {
        display: block;
    }
    .w70 {
        width: 100%;
    }
    .tabletW80 {
        width: 80%;
        margin: 0 auto;
        margin-top: $-space--1;
    }
    .tabletW50 {
        width: 50%;
    }
    .deskPicker {
        width: 90%;
        min-height: 51vh;
    }
}

@media (max-width: space.$breakpoint--md) {
    .contents {
        padding: $-space--1;
        display: block;
        padding-bottom: 60px;
    }
    .mobileW100 {
        width: 100%;
    }
    .title {
        margin-bottom: 0.5rem;
    }
    .w70 {
        margin-bottom: $-space--1;
    }
    .w50 {
        width: 100%;
        margin-bottom: $-space--1;
    }
    .column {
        padding-right: 0;
    }
    .reserveBtn {
        margin-right: 0;
    }
}
