@use 'src/styles/color.scss';
.button {
    background: none;
    appearance: none;
    border: none;
    border-radius: 0;
    &:focus,
    &:visited,
    &:focus-within,
    &:active,
    &:focus-visible {
        outline: none;
    }
}

.enabledButton {
    &:hover {
        cursor: pointer;
    }
}
